
import AOS from "aos"
import "aos/dist/aos.css"
import { mapGetters } from "vuex"

export default {
  mounted() {
    AOS.init({
      once: true
    });
    const sliderIndexSwiper = new swiper.default(".slider-index .swiper", {
      modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
      loop: true,
      autoplayDisableOnInteraction: false,
      autoplay: true,
      slidesPerView: 1,
      // autoHeight: true,
      watchSlidesProgress: true,
      scrollbar: false,
      speed: 1800,
      autoplay: {
        delay: 8000
      },
      on: {
        sliderMove: this.sliderMove,
        slideChangeTransitionStart: this.slideChangeTransitionStart
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: "true",
        type: "bullets",
        renderBullet: function(index, className) {
          return (
            '<span class="' +
            className +
            '">' +
            '<span class="swiper-pagination-bar"></span>' +
            '<span class="swiper-pagination-progress"></span>' +
            "</span>"
          );
        }
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },
  methods: {
    sliderMove() {
      document
        .querySelectorAll(".swiper-slide-next .slider-index__media")
        .forEach(val => {
          // val.style.display = 'none'
          // val.classList.remove("aos-init", "aos-animate");
          // AOS.init({
          // 	once: true
          // })
          // val.style.display = 'block'
        });
      document
        .querySelectorAll(".swiper-slide-next .slider-index__circle")
        .forEach(val => {
          val.style.display = "none";
          val.classList.remove("aos-init", "aos-animate");
          val.style.display = "block";
          val.classList.add("aos-init", "aos-animate");
        });
      // AOS.init()
    },
    slideChangeTransitionStart() {
      // document.querySelectorAll('.swiper-slide-active .slider-index__media').forEach((val) => {
      // 	val.style.display = 'none'
      // 	val.classList.remove("aos-init");
      // 	val.style.display = 'block'
      // })
      // document.querySelectorAll('.swiper-slide-next .slider-index__circle').forEach((val) => {
      // 	val.style.display = 'none'
      // 	val.classList.remove("aos-init");
      // 	val.style.display = 'block'
      // })
      // AOS.refreshHard()
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings"
    }),
    slides() {
      return [
        {
          id: 1,
          heading: "Новогодние скидки до 15.01.2025",
          content: `и комплект зимней резины в подарок`,
          link: "/cars",
          image: require("~/assets/img/slider-main/6.webp"),
          imageSmall: require("~/assets/img/slider-main/6-mobile.webp")
        },
        {
          id: 2,
          heading: "Лекс-Авто",
          content: `Большой автопарк автомобилей с пробегом`,
          link: "/cars",
          image: require("~/assets/img/slider-main/1.webp"),
          imageSmall: require("~/assets/img/slider-main/1-mobile.webp")
        },
        {
          id: 3,
          heading: `Автокредит`,
          content: `Ставка от ${this.settings.credit_percent}, персональные условия`,
          link: "/credit",
          image: require("~/assets/img/slider-main/2.webp"),
          imageSmall: require("~/assets/img/slider-main/2-mobile.webp")
        },
        {
          id: 4,
          heading: "TRADE-IN",
          content: `Выгода до ${this.settings.sale_tradein} ₽`,
          link: "/trade-in",
          image: require("~/assets/img/slider-main/3.webp"),
          imageSmall: require("~/assets/img/slider-main/3-mobile.webp")
        },
        {
          id: 5,
          heading: "ВЫКУП",
          content: `Выплата в день обращения, бесплатная диагностика`,
          link: "/buyout",
          image: require("~/assets/img/slider-main/4.webp"),
          imageSmall: require("~/assets/img/slider-main/4-mobile.webp")
        },
      ];
    }
  }
};
